body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-size: cover;
}

html,
body,
#root {
  height: 100%;
}

/* global scrollbar */
::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  background: #323232;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #f0407e;
}
::-webkit-scrollbar-thumb:hover {
  background: #f0407e;
}

#widget-three-dots-menu .MuiMenu-listbox {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -14px;
  right: -20px;
  padding-left: 0;
}
#widget-three-dots-menu .MuiMenu-listbox .MuiButtonBase-root {
  background: #101010;
  border-radius: 0;
}

.uppercase-text {
  text-transform: uppercase;
}
